import { render, staticRenderFns } from "./ReturnForm2.vue?vue&type=template&id=b3464ef8&scoped=true&"
var script = {}
import style0 from "./ReturnForm2.vue?vue&type=style&index=0&id=b3464ef8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3464ef8",
  null
  
)

export default component.exports